body {
  background-color: #437f97;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  background-color: #01295f;
  color: white;
  height: auto;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.header h1 {
  font-size: 4rem;
  margin: 10px;
}

.header a {
  color: inherit;
  text-decoration: none;
  font-size: 35px;
  margin: 10px;
}

.icons {
  width: 30%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.mission {
  width: 50%;
  margin: 100px auto;
  padding: 20px;
  background-color: white;
  color: black;
  box-shadow: 5px 5px 15px 1px;
  border-radius: 5px;
}

.project {
  width: 50%;
  margin: 0px auto;
  color: black;
  padding: 20px;
  font-size: 18px;
}

.project a {
  text-decoration: underline;
  color: inherit;
}

.project p {
  margin-bottom: 10px;
}

.headshot {
  display: flex;
  margin: 250px auto 0px auto;
  background-image: url('./assets/headshot.png');
  height: 300px;
  width: 300px;
  background-position: center;
  border-radius: 150px;
  background-size: cover;
  border: 1px solid black;
}

.headshot img {
  height: 200px;
  width: 200px;
  border-radius: 100px;
  margin: auto;
  border: 1px solid black;
}
